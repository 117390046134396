import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { TimePresetMap } from '../../app/constants/time-selection-form';
import { EnsembleContext } from '../../app/context/ensemble-context';
import { useTenant } from '../../app/context/tenant-context';
import { useUserId } from '../../app/hooks/accounts';
import { useEnsembleModelsQuery } from '../../app/hooks/tags';
import { useCustomGetView, useDeleteView, useSaveView, useUpdateView } from '../../app/hooks/view';

import { ChartMode } from './investigate-chart-context';
import { useLayoutContext } from './layout-context';

import {
  CRUser,
  Ensemble,
  HiddenTag,
  ParentTag,
  PersistTimeSelection,
  PersistView,
  Tag,
  TimePresetKeys,
  TimeRanges,
  TimeSelection,
  TrendSearchData,
  ViewState,
  ViewType,
} from '@controlrooms/models';

export const transformToViewState = (
  view: PersistView,
  ensembleFamily: Ensemble | null,
  tenant: number,
  overrideViewId = '',
): ViewState => {
  return {
    viewId: view.view_id || overrideViewId,
    name: view.view.name,
    selectedEnsemble: ensembleFamily,
    view_id: view.view_id || '',
    selectedMode: view.view.type,
    isHidden: false,
    isDirty: false,
    isDeleted: false,
    tenant_id: tenant,
    timeSelection: PersistTimeSelection.toTimeSelection(view.view.timeSelection),
    view: {
      ...{
        [ViewType.MONITOR]: {
          severityFilter: 1,
          showLimits: true,
          selectedFolders: [],
          selectedTags: [],
        },
        [ViewType.ANALYZE]: {
          showLimits: true,
          pinnedTags: [] as ParentTag[],
          hiddenTags: [] as HiddenTag[],
          selectedFolders: [],
          selectedTags: [],
          trendSearchData: {} as TrendSearchData,
        },
        [ViewType.INVESTIGATE]: {
          selectedFolders: [] as number[],
          selectedTags: {} as { [folder: number]: Tag[] },
          showAnomalies: true,
          organize: 'overlays' as ChartMode,
          groupByUom: false,
          showLimits: true,
        },
      },
      ...{
        [view.view.type]: {
          ...(view.view.type === 'monitor' && {
            selectedFolders: view.view.selectedFolders,
            ensemble_family_id: view.view.ensemble_family_id,
            severityFilter: view.view.severityFilter,
            showLimits: view.view.showMonitorLimits,
          }),
          ...(view.view.type === 'analyze' && {
            selectedFolders: view.view.selectedFolders,
            ensemble_family_id: view.view.ensemble_family_id,
            showLimits: view.view.showAnalyzeLimits,
            pinnedTags: view.view.pinnedTags,
            hiddenTags: view.view.hiddenTags,
          }),
          ...(view.view.type === 'investigate' && {
            ensemble_family_id: view.view.ensemble_family_id,
            selectedFolders: view.view.selectedFolders,
            selectedTags: view.view.selectedTags || [],
            showLimits: view.view.showInvestigateLimits,
            showAnomalies: view.view.showAnomalies,
            organize: view.view.chartMode,
            severityFilter: view.view.severityFilter,
            groupByUom: view.view.groupByUom,
          }),
        },
      },
    },
    timeSelectionHistory: [],
  };
};

export const constructPersistedView = (
  viewState: ViewState,
  viewName: string,
  activeModes: {
    [key: string]: ViewType;
  },
  currentUserId: number,
  shared = false,
): PersistView => {
  const selectedMode = activeModes[viewState.viewId];
  const persistTimeSelection = PersistTimeSelection.ofTimeSelection(viewState.timeSelection);
  return {
    view: {
      ...viewState.view[selectedMode],
      timeSelection: persistTimeSelection,
      type: selectedMode,
      selectedFolders: viewState.view[selectedMode].selectedFolders,
      name: viewName,
      ensemble_family_id: viewState?.selectedEnsemble?.family_id,
    },
    user_id: currentUserId,
    shared,
  };
};

interface ViewContextProps {
  viewId: string;
  viewState: ViewState;
  setViewState: Dispatch<SetStateAction<ViewState>>;
  selectedView: string;
  setSelectedView: Dispatch<SetStateAction<string>>;
  resetTenantViewState: () => void;
  handleShowLimits: (showLimits: boolean | undefined) => void;
  saveView: (name: string) => void;
  closeView: () => void;
  deleteView: () => void;
  updateView: (viewName: string) => void;
  createNewView: (name: string) => void;
  duplicateView: () => void;
  handleSystemSelection: (systems: number[]) => void;
  updateTimeSelection: (timeselection: TimeSelection, makeDirty?: boolean) => void;
  recordTimelineHistory: () => void;
  updateLocalView: (viewName: string, view: ViewState) => void;
  searchMode: boolean;
  setSearchMode: Dispatch<SetStateAction<boolean>>;
  setTrendSearchData: (trendSearchData: TrendSearchData) => void;
  clearTrendSearchData: () => void;
  getQueryKeyWithViewId: (label: string) => string;
  handleUndoTimeSelection: () => void;
}

const defaultTimeSelections: TimeSelection = {
  startTime: dayjs().subtract(12, 'hour'),
  endTime: dayjs(),
  timezone: dayjs.tz.guess(), // Default to user timezone
  timeRange: TimeRanges.PRESET,
  streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
  nowSelected: false,
};

const defaultState = {
  viewId: 'default',
  viewState: {} as ViewState,
  setViewState: () => null,
  selectedView: 'default',
  setSelectedView: () => null,
  resetTenantViewState: () => null,
  handleShowLimits: () => null,
  saveView: () => null,
  createNewView: () => null,
  closeView: () => null,
  deleteView: () => null,
  updateView: () => null,
  duplicateView: () => null,
  handleSystemSelection: () => null,
  updateTimeSelection: () => null,
  recordTimelineHistory: () => null,
  updateLocalView: () => null,
  searchMode: false,
  setSearchMode: () => null,
  setTrendSearchData: () => null,
  clearTrendSearchData: () => null,
  getQueryKeyWithViewId: (label: string) => `${label}-default`,
  handleUndoTimeSelection: () => null,
};

export const ViewContext = createContext<ViewContextProps>(defaultState);

export const ViewContextProvider: React.FC<{
  viewId: string;
  children: ReactNode;
  tempNode?: {
    viewType: string;
    referenceId: string;
    state: {
      selectedFolders: string;
    };
  };
}> = ({ viewId, children, tempNode }) => {
  const { tenant: currentTenantId } = useTenant();
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const { selectedEnsemble } = useContext(EnsembleContext);
  const { activeModes, activeView, setViewIds, setActiveModes, setActiveView, setSavedViewIds } =
    useLayoutContext();

  const currentUserId = useUserId();

  const queryClient = useQueryClient();
  const cachedUser = queryClient.getQueryData<CRUser>(['user-properties', currentUserId]);

  const defaultViewHash = cachedUser?.preferences?.defaultView?.[currentTenantId];

  const {
    data: persistDefaultView,
    isError: defaultViewError,
    isLoading,
  } = useCustomGetView(
    defaultViewHash || '',
    !!defaultViewHash && defaultViewHash !== '' && viewId === 'default',
  );

  const defaultViewState: ViewState = useMemo(
    () => ({
      viewId,
      name: viewId === 'default' ? 'default' : '',
      selectedMode: tempNode?.viewType === 'ALERT_LINK' ? ('' as ViewType) : ViewType.MONITOR,
      isHidden: false,
      isDirty: viewId === 'default' ? false : true,
      isDeleted: false,
      tenant_id: currentTenantId,
      timeSelection: defaultTimeSelections,
      view: {
        [ViewType.MONITOR]: {
          type: ViewType.MONITOR,
          severityFilter: 0,
          showLimits: true,
          pinnedTags: [],
          hiddenTags: [],
          selectedFolders: [],
          selectedTags: [],
        },
        [ViewType.ANALYZE]: {
          type: ViewType.ANALYZE,
          selectedTags: [],
          showLimits: true,
          pinnedTags: [] as ParentTag[],
          hiddenTags: [] as HiddenTag[],
          selectedFolders:
            tempNode?.state.selectedFolders?.split(',').map(Number) || ([] as number[]),
          trendSearchData: {} as TrendSearchData,
        },
        [ViewType.INVESTIGATE]: {
          selectedFolders: [] as number[],
          selectedTags: {} as { [folder: number]: Tag[] },
          showAnomalies: true,
          organize: 'overlays' as ChartMode,
          groupByUom: false,
          showLimits: true,
        },
      },
      view_id: '',
      timeSelectionHistory: [],
      selectedEnsemble: selectedEnsemble,
      isTrendSearch: false,
    }),
    [
      viewId,
      tempNode?.viewType,
      tempNode?.state.selectedFolders,
      currentTenantId,
      selectedEnsemble,
    ],
  );

  useEffect(() => {
    if (viewId === 'default' && !isLoading && !defaultViewError && persistDefaultView) {
      const ensembleFamily = ensembleFamilies.find(
        (e) => e.family_id === persistDefaultView?.view?.ensemble_family_id,
      );
      const transformedView = transformToViewState(
        persistDefaultView,
        ensembleFamily || null,
        currentTenantId,
      );
      const localSavedView = sessionStorage.getItem('default');
      if (!localSavedView || localSavedView == '') return;
      setViewState({
        ...transformedView,
        viewId: 'default',
        view_id: '',
      });
      setActiveModes((prev) => ({
        ...prev,
        ['default']: prev['default'] || persistDefaultView.view.type,
      }));
      setActiveView((prev) => (prev !== '' ? prev : 'default'));
    }
  }, [
    currentTenantId,
    defaultViewError,
    isLoading,
    persistDefaultView,
    setActiveModes,
    setActiveView,
    viewId,
    ensembleFamilies,
  ]);

  // useEffect(() => {
  //   if (newView) {
  //     setViewState(defaultViewState);
  //   } else if (persistView) {
  //     const savedView = transformToViewState(persistView, null, 0);
  //     // If newView is true and persistView data is available, set it to viewState
  //     setViewState(savedView);
  //   }
  // }, [defaultViewState, newView, persistView]);

  const { mutateAsync: createView } = useSaveView();
  const { mutateAsync: deletePersistedView } = useDeleteView();
  const { mutateAsync: updatePersistedView } = useUpdateView();
  const [searchMode, setSearchMode] = useState(defaultState.searchMode);

  const resetTenantViewState = useCallback(() => {
    sessionStorage.clear();
    setViewState(defaultViewState);
  }, [defaultViewState]);

  // Retrieve state from session storage or use default
  const [viewState, setViewState] = useState<ViewState>(() => {
    const savedViewState = sessionStorage.getItem(viewId);
    if (savedViewState) {
      const parsedViewState: ViewState = JSON.parse(savedViewState);
      parsedViewState.timeSelection.startTime = dayjs(parsedViewState.timeSelection.startTime);
      parsedViewState.timeSelection.endTime = dayjs(parsedViewState.timeSelection.endTime);
      return parsedViewState;
    }
    return defaultViewState;
  });

  const [selectedView, setSelectedView] = useState<string>(() => {
    const savedSelectedView = sessionStorage.getItem('selectedView');
    return savedSelectedView ? savedSelectedView : 'default';
  });

  // Persist state to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(viewId, JSON.stringify(viewState));
  }, [viewId, viewState]);
  useEffect(() => {
    if (viewState.view_id !== 'default' && viewState.view_id !== '') {
      setSavedViewIds((prev) => {
        const savedViewIds = Array.from(new Set([...prev, ...[viewState.view_id]]));
        return viewId === 'default' ? prev : savedViewIds;
      });
    }
  }, [persistDefaultView, setSavedViewIds, viewId, viewState]);

  useEffect(() => {
    sessionStorage.setItem('selectedView', selectedView);
  }, [selectedView]);

  const handleShowLimits = useCallback(
    (newShowLimitsValue: boolean | undefined) => {
      setViewState((prevState) => {
        const selectedMode = activeModes[activeView];
        return {
          ...prevState,
          view: {
            ...prevState.view,
            [selectedMode]: {
              ...prevState.view[selectedMode],
              showLimits: !!newShowLimitsValue,
            },
          },
        };
      });
    },
    [activeModes, activeView],
  );

  // Close view
  const closeView = useCallback(() => {
    if (viewState.viewId === 'default') return;

    if (activeView === viewState.viewId) {
      setActiveView('default');
    }
    setViewIds((prev) => {
      const newState = [...prev];
      const index = newState.indexOf(viewState.viewId);
      newState.splice(index, 1);
      return newState;
    });
    sessionStorage.removeItem(viewId);
  }, [activeView, setActiveView, setViewIds, viewId, viewState]);

  // Update time selection in view
  const updateTimeSelection = useCallback((newTimeselection: TimeSelection, makeDirty = false) => {
    setViewState((prev) => ({
      ...prev,
      isDirty: makeDirty !== undefined ? makeDirty : prev.isDirty,
      timeSelection: newTimeselection,
    }));
  }, []);

  // Update and record the time selection history
  const recordTimelineHistory = useCallback(() => {
    setViewState((prev) => ({
      ...prev,
      isDirty: true,
      timeSelectionHistory: [...prev.timeSelectionHistory, prev.timeSelection],
    }));
  }, []);

  // Update view
  const updateView = useCallback(
    async (viewName = '') => {
      const persistView = constructPersistedView(viewState, viewName, activeModes, currentUserId);
      await updatePersistedView({ view: persistView, view_id: viewState.viewId });
    },
    [viewState, activeModes, currentUserId, updatePersistedView],
  );

  // Save view
  const saveView = useCallback(
    async (viewName = '') => {
      const persistView = constructPersistedView(viewState, viewName, activeModes, currentUserId);
      if (viewState.viewId === 'default' && viewState.view_id === '') {
        // TODO Update user tenant preference
        const hash = await createView(persistView);
        // await updateUserTenantPreference({ user_id: currentUserId, tenant_id: defaultView: hash });
        setViewState((prev) => ({
          ...prev,
          isDirty: false,
          name: viewName,
          view_id: hash,
        }));
        return;
      }

      if (viewState.view_id != '') {
        try {
          await updateView(viewName);
          setViewState((prev) => {
            const newState = { ...prev, isDirty: false, name: viewName };
            return newState;
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const hash = await createView(persistView);
          setViewState((prev) => {
            const newState = { ...prev, isDirty: false, viewId: hash, view_id: hash };
            return newState;
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [activeModes, createView, currentUserId, updateView, viewState],
  );

  const createNewView = useCallback(
    async (viewName: string) => {
      const persistView = constructPersistedView(viewState, viewName, activeModes, currentUserId);
      try {
        const hash = await createView(persistView);
        setViewState((prev) => {
          const newState = { ...prev, isDirty: false, view_id: hash, name: viewName };
          return newState;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [activeModes, createView, currentUserId, viewState],
  );

  const updateLocalView = useCallback((viewName: string, view: ViewState) => {
    setViewState((prev) => {
      const newState = { ...prev, ...view };
      return newState;
    });
  }, []);

  const handleSystemSelection = useCallback(
    (systems: number[]) => {
      const selectedMode = activeModes[viewId];
      setViewState((prev) => {
        const newState = { ...prev };
        newState.isDirty = true;
        newState.view[selectedMode].selectedFolders = systems;
        return newState;
      });
    },
    [activeModes, viewId],
  );

  const setTrendSearchData = useCallback((trendSearchData: TrendSearchData) => {
    setViewState((prev) => {
      const newState = { ...prev };
      newState.view[ViewType.ANALYZE].trendSearchData = trendSearchData;
      newState.isTrendSearch = true;
      return newState;
    });
  }, []);

  const clearTrendSearchData = useCallback(() => {
    setViewState((prev) => {
      const newState = { ...prev };
      newState.isTrendSearch = false;
      delete newState.view[ViewType.ANALYZE].trendSearchData;
      return newState;
    });
  }, []);

  // Delete view
  const deleteView = useCallback(async () => {
    await deletePersistedView(viewState.view_id);
    //Remove from active views
    if (activeView === viewState.viewId) {
      setActiveView('default');
    }
    //Remove from active modes
    setActiveModes((prev) => {
      const newState = { ...prev };
      delete newState[viewState.viewId];
      return newState;
    });
    //Remove from viewIds
    setViewIds((prev) => {
      const newState = [...prev];
      const index = newState.indexOf(viewState.viewId);
      newState.splice(index, 1);
      return newState;
    });
    //Remove from sessionStorage
    sessionStorage.removeItem(viewState.viewId);
  }, [
    activeView,
    deletePersistedView,
    setActiveModes,
    setActiveView,
    setViewIds,
    viewState.viewId,
    viewState.view_id,
  ]);

  // Duplicate view
  const duplicateView = useCallback(() => {
    const uuid = uuidv4();
    const currentSessionView = sessionStorage.getItem(viewId);
    if (!currentSessionView) {
      console.error('No view found with the viewid', viewId);
      return;
    }

    const currView: ViewState = JSON.parse(currentSessionView);
    currView.isDirty = true;
    currView.viewId = uuid;
    currView.view_id = '';
    currView.name = '';
    sessionStorage.setItem(uuid, JSON.stringify(currView));
    setViewIds((prevItems) => [...prevItems, uuid]);
    setActiveModes((prev) => ({ ...prev, [uuid]: activeModes[viewId] }));
    setActiveView(uuid);
  }, [activeModes, setActiveModes, setActiveView, setViewIds, viewId]);

  const getQueryKeyWithViewId = useCallback((label: string) => `${label}-${viewId}`, [viewId]);

  const handleUndoTimeSelection = useCallback(() => {
    const resetTo = viewState.timeSelectionHistory.pop();
    if (resetTo) {
      setViewState((prev) => ({
        ...prev,
        isDirty: true,
        timeSelection: {
          ...resetTo,
          startTime: dayjs(resetTo.startTime),
          endTime: dayjs(resetTo.endTime),
        },
        timeSelectionHistory: [...viewState.timeSelectionHistory],
      }));
    }
  }, [viewState.timeSelectionHistory, setViewState]);

  const appState = useMemo(
    () => ({
      viewId,
      viewState,
      setViewState,
      resetTenantViewState,
      selectedView,
      setSelectedView,
      handleShowLimits,
      saveView,
      createNewView,
      closeView,
      deleteView,
      updateView,
      duplicateView,
      updateTimeSelection,
      recordTimelineHistory,
      handleSystemSelection,
      updateLocalView,
      searchMode,
      setSearchMode,
      setTrendSearchData,
      clearTrendSearchData,
      getQueryKeyWithViewId,
      handleUndoTimeSelection,
    }),
    [
      viewId,
      viewState,
      resetTenantViewState,
      selectedView,
      handleShowLimits,
      saveView,
      createNewView,
      closeView,
      deleteView,
      updateView,
      duplicateView,
      updateTimeSelection,
      recordTimelineHistory,
      handleSystemSelection,
      updateLocalView,
      searchMode,
      setSearchMode,
      setTrendSearchData,
      clearTrendSearchData,
      getQueryKeyWithViewId,
      handleUndoTimeSelection,
    ],
  );

  return <ViewContext.Provider value={appState}>{children}</ViewContext.Provider>;
};

export const useViewContext = (): ViewContextProps => {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('useViewContext must be used within a ViewContextProvider');
  }
  return context;
};
