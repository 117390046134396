import React, { useContext } from 'react';

import { AnalyzeContext } from '../../context/analyze-context';
import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';

import { SeverityFilterContainer } from './styles';

import { Switch } from '@controlrooms/components';

interface Props {
  label?: string;
  showSlider?: boolean;
}

const LimitsToggle: React.FC<Props> = ({ label }) => {
  const { setShowAnalyzeLimits } = useContext(AnalyzeContext);
  const { viewState, handleShowLimits } = useViewContext();
  const { activeModes, activeView } = useLayoutContext();
  const selectedMode = activeModes[activeView];

  const { showLimits } = viewState.view[selectedMode];

  // const { showLimits } = useContext(AppContext);
  // const START_TIME_PARAM = 'startTime';
  // const END_TIME_PARAM = 'endTime';
  // const [search] = useSearchParams();
  // const startTimeParams = search.get(START_TIME_PARAM);
  // const endTimeParams = search.get(END_TIME_PARAM);

  // const toggleFiltersCheck = useCallback(
  //   (checked: boolean) => {
  //     chartViewFilters.map((filterValue) => {
  //       if (filterValue.value !== ViewFiltersValue.FREQUENT_VALUE) {
  //         filterValue.checked = checked;
  //       }
  //     });
  //   },
  //   [chartViewFilters],
  // );

  // useEffect(() => {
  //   if (!showLimits) {
  //     handleShowLimits(false);
  //   }
  //   if (startTimeParams || endTimeParams) {
  //     handleShowLimits(true);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   toggleFiltersCheck(!!showLimits);
  //   // eslint-disable-next-line
  // }, [showLimits]);

  return (
    <SeverityFilterContainer style={{ marginLeft: '1rem', gap: '0' }}>
      {label ? <span className="label">{label}</span> : <span className="label">Show Limits</span>}
      <div>
        <Switch
          selected={showLimits}
          onClick={(e, selected) => {
            handleShowLimits(selected);
            setShowAnalyzeLimits(selected);
            // toggleFiltersCheck(selected);
          }}
          dataTestId={'limits'}
        />
      </div>
    </SeverityFilterContainer>
  );
};

export default LimitsToggle;
