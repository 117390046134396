import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useViewContext } from './view-context';

import {
  AnalyzeSavedView,
  GroupTooltipData,
  SearchTimeRangeData,
  TimeRanges,
  ViewType,
} from '@controlrooms/models';
import { ParentTag } from '@controlrooms/models';

export interface AnalyzeContextProps {
  updateView: (view: AnalyzeSavedView) => void;
  selectedFolders: number[];
  selectedViewTitle: string;
  groupTooltipData?: GroupTooltipData;
  setGroupTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  labelTooltipData?: GroupTooltipData;
  setLabelTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  searchTimeRangeData?: SearchTimeRangeData;
  setSearchTimeRangeData: Dispatch<SetStateAction<SearchTimeRangeData | undefined>>;
  pinnedTags: ParentTag[];
  currentFolder: number | string | null;
  setCurrentFolder: Dispatch<SetStateAction<number | string | null>>;
  setSelectedViewTitle: Dispatch<SetStateAction<string>>;
  showHiddenTags: boolean;
  setShowHiddenTags: Dispatch<SetStateAction<boolean>>;
  showAnalyzeLimits: boolean;
  setShowAnalyzeLimits: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  updateView: () => null,
  selectedFolders: [],
  selectedViewTitle: 'Default View',
  groupTooltipData: undefined,
  setGroupTooltipData: () => undefined,
  labelTooltipData: undefined,
  setLabelTooltipData: () => undefined,
  searchTimeRangeData: undefined,
  setSearchTimeRangeData: () => undefined,
  pinnedTags: [],
  currentFolder: null,
  setCurrentFolder: () => null,
  showHiddenTags: false,
  setShowHiddenTags: () => null,
  showAnalyzeLimits: true,
  setShowAnalyzeLimits: () => null,
  setSelectedViewTitle: () => null,
};

export const AnalyzeContext = createContext<AnalyzeContextProps>(defaultState);

const AnalyzeContextProvider: React.FC = ({ children }) => {
  const { viewState } = useViewContext();

  const [selectedViewTitle, setSelectedViewTitle] = useState<string>('');

  const [showHiddenTags, setShowHiddenTags] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState<number | string | null>(null);

  const [showAnalyzeLimits, setShowAnalyzeLimits] = useState<boolean>(
    defaultState.showAnalyzeLimits,
  );

  const [groupTooltipData, setGroupTooltipData] = useState<GroupTooltipData>();
  const [labelTooltipData, setLabelTooltipData] = useState<GroupTooltipData>();
  const [searchTimeRangeData, setSearchTimeRangeData] = useState<SearchTimeRangeData>();

  const updateView = useCallback((view: AnalyzeSavedView) => {
    if (
      view.timeSelection.timeRange === TimeRanges.PRESET &&
      view.timeSelection.streamingTimeInSeconds
    ) {
      view.timeSelection.endTime = dayjs().toISOString();
      view.timeSelection.startTime = dayjs()
        .subtract(view.timeSelection.streamingTimeInSeconds, 'seconds')
        .toISOString();
    }
    setSelectedViewTitle(view.name || '');
    setShowAnalyzeLimits(view.showAnalyzeLimits || true);
  }, []);

  const analyzeState = useMemo(
    () => ({
      updateView,
      selectedFolders: viewState.view[ViewType.ANALYZE].selectedFolders,
      groupTooltipData,
      setGroupTooltipData,
      labelTooltipData,
      setLabelTooltipData,
      searchTimeRangeData,
      setSearchTimeRangeData,
      pinnedTags: viewState.view[ViewType.ANALYZE].pinnedTags as ParentTag[],
      currentFolder,
      setCurrentFolder,
      showHiddenTags,
      setShowHiddenTags,
      showAnalyzeLimits,
      setShowAnalyzeLimits,
      selectedViewTitle,
      setSelectedViewTitle,
    }),
    [
      currentFolder,
      groupTooltipData,
      labelTooltipData,
      searchTimeRangeData,
      updateView,
      showHiddenTags,
      showAnalyzeLimits,
      setShowAnalyzeLimits,
      selectedViewTitle,
      setSelectedViewTitle,
      viewState,
    ],
  );

  return <AnalyzeContext.Provider value={analyzeState}>{children}</AnalyzeContext.Provider>;
};

export default AnalyzeContextProvider;
