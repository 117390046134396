import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { generatePath, matchPath, useLocation } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { customScrollPrimary } from '../../../app/global-styles';
import {
  useGetUserCollections,
  useGetUserViews,
  useUpdateUserPreferences,
  useUserId,
} from '../../../app/hooks/accounts';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import {
  useDeleteCollection,
  useSaveCollection,
  useSaveView,
  useUpdateCollection,
  useUpdateView,
} from '../../../app/hooks/view';
import { TimeRangeUtil } from '../../../app/utils/time';
import { useLayoutContext } from '../../context/layout-context';
import {
  constructPersistedView,
  transformToViewState,
  useViewContext,
} from '../../context/view-context';
import { ExportModal } from '../export-modal';

import { Icon, Modal, ModalContents, TextInput, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';
import { useClickOutside } from '@controlrooms/hooks';
import {
  CRUser,
  DateFormats,
  PersistCollection,
  PersistView,
  TimeSelection,
  ViewState,
  ViewType,
  TimeRangesType,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const Tabs = styled.div`
  display: flex;
  // border-bottom: ${({ theme }) => `2px solid ${theme.v2.tab.tab.backgroundColor}`};
  .container {
    display: flex;

    &.hasMoreTabs {
      .label {
        display: none;
      }
    }
  }

  .add-new,
  .viewsets-option {
    width: 50px;
    padding: 0px;
    height: 100%;
  }

  .tabs {
    height: 50px;
    background: ${({ theme }) => theme.v2.tab.tab.tabContainerBG};
    flex-grow: 1;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
`;

const TabBaseLine = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.v2.tab.tab.backgroundColor};

  width: 100%;
  height: 2px;
  box-shadow: none;
`;

const Tab = styled.button<{ isActive?: boolean }>`
  border: none;
  border: ${({ theme }) => `1px solid ${theme.v2.tab.tab.backgroundColor}`};
  border-right: ${({ theme }) => `1px solid ${theme.v2.tab.tab.tabContainerBG}`};
  background-color: ${({ theme }) => theme.v2.tab.tab.backgroundColor};
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 0px;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  .subMenu-link {
    padding: 10px;
  }
  ${({ isActive }) =>
    isActive &&
    `
    border-bottom: 2px solid #93BBC3;
    ${TabBaseLine} {
      background-color: #93BBC3;
      box-shadow: 0px -3px 10px 2px #93BBC3;
    }
  `}
  ${({ isActive, theme }) =>
    !isActive &&
    `
    .label {
      text-transform: capitalize;
      ::before {
        content: '';
        display: block;
        width: 100%;
        height: 20px;
        background-color: ${theme.v2.tab.tab.backgroundColor};
      }
    }
  `}
    &:hover {
    border-bottom: 2px solid #93bbc3;
    ${TabBaseLine} {
      background-color: #93bbc3;
      box-shadow: 0px -3px 10px 2px #93bbc3;
    }
  }

  &:focus {
    outline: none;
  }

  &.viewsets-option {
    padding: 5px;
  }
  .tab-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &.collection {
  }
  .label {
    text-transform: capitalize;
    max-width: 150px;
    display: flex;
    flex: 1 0 0;
    padding-left: 10px;
    overflow: hidden;
    color: ${({ theme }) => theme.v2.tab.tab.textColor};
    text-overflow: ellipsis;

    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    white-space: nowrap;
    align-items: center;

    ::before {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: ${({ theme }) => theme.v2.tab.tab.backgroundColor};
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: absolute;
  top: 40px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  border-radius: 4px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.6);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    &.expanded-list {
      max-height: 300px;
      ${customScrollPrimary}
      overflow-y: auto;
    }

    li {
      display: flex;
      padding: 5px 25px;
      align-items: center;
      align-self: stretch;
      cursor: pointer;

      &.separator {
        border-bottom: ${({ theme }) => `1px solid ${theme.v2.tab.tab.backgroundColor}`};
        background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
      }

      &.disabled {
        cursor-event: not-allowed;
        opacity: 0.5;
      }

      &.option {
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        color: ${({ theme }) => theme.modal.panel.color};
        &:hover {
          background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
        }
        .view-name {
          width: 300px;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;
        }
      }
      &.label {
        font-family: 'Open Sans';
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 9px; /* 100% */
        text-transform: uppercase;
        background-color: ${({ theme }) => theme.sidebar.folders.headerBackgroundColor};
        color: ${({ theme }) => theme.modal.panel.color};
        margin-top: 0px;
      }
    }
  }
`;

export const TabContainer = () => {
  //TODO: Add logic to list all viewsets on the options
  // const { data: viewsets } = useViewSet();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { data: userViews } = useGetUserViews();
  const { data: userCollections } = useGetUserCollections();
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const { tenant } = useTenant();

  const { pathname } = useLocation();
  const isView = matchPath(Paths.VIEWS, pathname);

  const {
    setViewIds,
    setActiveModes,
    activeModes,
    setActiveView,
    viewIds,
    viewCollection,
    setViewCollection,
  } = useLayoutContext();
  const [showCollectionSubmenu, setShowCollectionSubmenu] = useState(false);
  const [showSaveCollectionModal, setShowSaveCollectionModal] = useState(false);
  const hasNewTabs = viewIds.length > 3;

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setShowCollectionSubmenu(false));

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (scrollRef.current) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleAddNewView = () => {
    const uuid = uuidv4();
    setViewIds((prevItems) => [...prevItems, uuid]);
    setActiveModes((prev) => ({ ...prev, [uuid]: ViewType.MONITOR }));
    setActiveView(uuid);
    if (viewCollection.name !== '') {
      setViewCollection((prev) => ({ ...prev, isDirty: true }));
    }
  };

  const handleCloseAll = () => {
    setActiveView('default');
    viewIds.forEach((id) => {
      if (id !== 'default') sessionStorage.removeItem(id);
    });
    setViewIds(['default']);
    setActiveModes({ default: activeModes['default'] });
    setViewCollection({
      name: '',
      viewsIds: [],
      views: [],
    });
  };

  const generateView = (view: PersistView) => {
    const ensembleFamily = ensembleFamilies.find(
      (e) => e.family_id === view?.view?.ensemble_family_id,
    );
    view.view = {
      ...view.view,
      timeSelection: {
        ...view.view.timeSelection,
        timePreset: 'custom',
        timeRange: 'custom' as TimeRangesType,
        streamingTimeInSeconds: 0,
      },
    };
    const viewState = transformToViewState(view, ensembleFamily || null, tenant);
    sessionStorage.setItem(view.view_id || 'error', JSON.stringify(viewState));
    return viewState;
  };

  const handleLoadView = (view: PersistView) => {
    const viewState = generateView(view);

    setViewIds((prevItems) => [...prevItems, viewState.view_id]);
    setActiveModes((prev) => ({ ...prev, [viewState.view_id]: view.view.type }));
    setActiveView(viewState.view_id);
  };

  const handleLoadCollection = (collection: PersistCollection) => {
    if (collection.views) {
      handleCloseAll();
      collection.views?.forEach((view: PersistView) => {
        const viewState = generateView(view);
        setViewIds((prevItems) => [...prevItems, viewState.view_id]);
        setActiveModes((prev) => ({ ...prev, [viewState.view_id]: view.view.type }));
      });
      setActiveView(collection.views[0].view_id || 'default');
      setViewCollection((prev) => ({
        ...prev,
        viewsIds: collection.view_ids,
        name: collection.collection_name,
        isDirty: false,
        collectionId: collection.collection_id,
      }));
    }
  };

  const handleCreateNewCollection = () => {
    setShowSaveCollectionModal(true);
  };

  return (
    <Tabs>
      <div
        className={`container default ${hasNewTabs || viewCollection.name ? 'hasMoreTabs' : ''}`}
        id="default-tab-container"
      ></div>
      {viewCollection.name !== '' && isView && <CollectionTab />}
      <div className="container tabs" id="tab-container" ref={scrollRef}></div>
      {isView && (
        <div className="container menu">
          <Tooltip label={'Add New View'}>
            <Tab className="add-new" onClick={() => handleAddNewView()}>
              <div className="tab-content">
                <Icon name={ICONS.Plus} width="15" height="20" />
              </div>
            </Tab>
          </Tooltip>
          <Tab
            className="viewsets-option"
            onClick={() => setShowCollectionSubmenu((prev) => !prev)}
          >
            <div className="tab-content">
              <Icon name={ICONS.Kebab} width="15" height="20" color="#A0A38D" />
            </div>
          </Tab>
          {showCollectionSubmenu && (
            <Menu
              ref={menuRef}
              style={{ right: '10px', zIndex: 2, color: '#faffda', width: '300px' }}
            >
              <ul>
                {viewIds.length > 1 && (
                  <li
                    className={`option`}
                    onClick={() => {
                      handleCloseAll();
                      setShowCollectionSubmenu(false);
                    }}
                  >
                    Close All Views
                  </li>
                )}
                <li
                  className={`option ${
                    viewIds.length === 1 && viewIds[0] === 'default' ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    handleCreateNewCollection();
                    setShowCollectionSubmenu(false);
                  }}
                >
                  Create New Collection
                </li>
                {/* <li className={`option`}>Manage your views and collections</li> */}
                {userCollections?.length ? <li className="separator"></li> : null}
                {userCollections?.length ? <li className={`label`}>YOUR COLLECTIONS</li> : null}
                {userCollections?.length ? (
                  <ul className="expanded-list">
                    <Virtuoso
                      style={{
                        height: `${(userCollections?.length || 1) * 23}px`,
                        maxHeight: '300px',
                      }}
                      className="my-view-container"
                      totalCount={userCollections ? userCollections.length : 0}
                      overscan={10}
                      itemContent={(index) => {
                        if (userCollections?.length) {
                          const collection = userCollections[index];
                          return (
                            <Tooltip label={collection.collection_name || 'Unnamed Collection'}>
                              <li
                                className={`option`}
                                onClick={() => {
                                  handleLoadCollection(collection);
                                  setShowCollectionSubmenu(false);
                                }}
                              >
                                {collection.collection_name || 'Unnamed Collection'}
                              </li>
                            </Tooltip>
                          );
                        }
                      }}
                    />
                  </ul>
                ) : null}
                {userViews?.length ? <li className="separator"></li> : null}
                {userViews?.length ? <li className={`label`}>YOUR VIEWS</li> : null}
                {userViews?.length ? (
                  <ul className="expanded-list">
                    <Virtuoso
                      style={{
                        height: `${(userViews?.length || 1) * 23}px`,
                        maxHeight: '300px',
                      }}
                      className="my-view-container"
                      totalCount={userViews ? userViews.length : 0}
                      overscan={10}
                      itemContent={(index) => {
                        if (userViews?.length) {
                          const view = userViews[index];
                          return (
                            <Tooltip label={view?.view?.name || 'Unnamed view'}>
                              <li
                                className={`option`}
                                onClick={() => {
                                  handleLoadView(view);
                                  setShowCollectionSubmenu(false);
                                }}
                              >
                                <div className="view-name">
                                  {view?.view?.name || 'Unnamed view'}
                                </div>
                              </li>
                            </Tooltip>
                          );
                        }
                      }}
                    />
                  </ul>
                ) : null}
                {/* <li className="separator"></li> */}
                {/* <li className={`label`}>SHARED WITH YOU</li> */}
              </ul>
            </Menu>
          )}
        </div>
      )}
      {showSaveCollectionModal ? (
        <SaveCollectionModal onClose={() => setShowSaveCollectionModal(false)} />
      ) : null}
    </Tabs>
  );
};

const SaveCollectionModal = ({ onClose }: { onClose: () => void }) => {
  const { setViewCollection, savedViewIds } = useLayoutContext();
  const { mutateAsync: createCollection } = useSaveCollection();

  const { register, watch } = useForm<{ name: string; description: string }>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const handleCreateCollection = async () => {
    const collectionName = watch(`name`);
    if (collectionName) {
      const persistedCollection = {
        name: collectionName,
        viewsIds: savedViewIds,
      };
      try {
        const response = await createCollection({
          collection_name: persistedCollection.name,
          view_ids: persistedCollection.viewsIds,
        });
        setViewCollection((prev) => ({
          ...prev,
          name: response.collection_name,
          viewsIds: persistedCollection.viewsIds,
          isDirty: false,
          collectionId: response.collection_id,
          views: response.views || [],
        }));
      } catch (error) {
        console.error(error);
        setViewCollection({
          name: persistedCollection.name,
          viewsIds: persistedCollection.viewsIds,
          isDirty: false,
          views: [],
        });
      } finally {
        onClose();
      }
    }
  };
  return (
    <Modal open={true}>
      <ModalContents
        title={'Save new collection'}
        styles={{ content: { maxWidth: '450px' } }}
        confirmButtonText="Save Collection"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onClose();
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          handleCreateCollection();
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Save the views you are currently open as a new collection.</p>
          <div>
            <FieldLabel>Name</FieldLabel>
            <TextInput
              type="string"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              value={watch(`name`)}
              className="view-name"
              label=""
              placeholder=""
              errorId={'view-name-required'}
              disabled={false}
              {...register(`name`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view name';
                  }
                  return true;
                },
              })}
              errorMessage={''}
            />
            <br />
            <FieldLabel>Description</FieldLabel>
            <TextInput
              type="string"
              value={watch(`description`)}
              className="view-description"
              label=""
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              placeholder=""
              errorId={'view-description-required'}
              disabled={false}
              {...register(`description`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view description';
                  }
                  return true;
                },
              })}
              errorMessage={''}
            />
          </div>
        </div>
      </ModalContents>
    </Modal>
  );
};

export const preparePrimaryLabel = (timeselection: TimeSelection) => {
  const hoursDisplay = TimeRangeUtil.calculateTimePreset(
    'label',
    timeselection.streamingTimeInSeconds || 0,
  );
  if (timeselection.nowSelected && !hoursDisplay) {
    return (
      <>
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          DateFormats.DATE,
        )}{' '}
        - Now
      </>
    );
  }
  return (
    hoursDisplay || (
      <span className="time">
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}{' '}
        -{' '}
        {TimeUtils.toTimezone(timeselection.endTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}
      </span>
    )
  );
};

const CollectionTab = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const {
    viewIds,
    setViewIds,
    activeModes,
    setActiveModes,
    setActiveView,
    viewCollection,
    setViewCollection,
  } = useLayoutContext();
  const subMenuRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(subMenuRef, () => setOpenSubMenu(false));

  const { mutateAsync: updateCollection } = useUpdateCollection();
  const { mutateAsync: deletePersistedView } = useDeleteCollection();

  const handleCloseAll = useCallback(() => {
    setActiveView('default');
    viewIds.forEach((id) => {
      if (id !== 'default') sessionStorage.removeItem(id);
    });
    setViewIds(['default']);
    setActiveModes({ default: activeModes['default'] });
    setViewCollection({
      name: '',
      viewsIds: [],
      views: [],
    });
  }, [activeModes, setActiveModes, setActiveView, setViewCollection, setViewIds, viewIds]);

  const handleUpdate = useCallback(async () => {
    const collection_id = viewCollection.collectionId;
    const viewIds = viewCollection.viewsIds.filter((id) => id !== 'default');
    try {
      if (collection_id)
        await updateCollection({
          collection_id: collection_id,
          collection: {
            collection_name: viewCollection.name,
            view_ids: viewIds,
          },
        });
      setViewCollection((prev) => ({ ...prev, isDirty: false }));
    } catch (error) {
      console.error(error);
    } finally {
      setOpenSubMenu(false);
    }
  }, [
    setViewCollection,
    updateCollection,
    viewCollection.collectionId,
    viewCollection.name,
    viewCollection.viewsIds,
  ]);

  const handleDelete = useCallback(async () => {
    const collection_id = viewCollection.collectionId;
    if (collection_id) await deletePersistedView(collection_id);
    handleCloseAll();
  }, [deletePersistedView, handleCloseAll, viewCollection.collectionId]);

  if (!viewCollection.name || viewCollection.name === '') {
    return null;
  }

  return (
    <Tab isActive={false}>
      <div className="tab-content" style={{ paddingLeft: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Icon name={ICONS.Folder} width="15" height="20" />
          <span className="label">{viewCollection.name}</span>
        </div>
        <div ref={subMenuRef}>
          <Icon
            className="subMenu-link"
            name={ICONS.Chevron}
            width="10"
            color="#A0A38D"
            onClick={(e) => {
              e.stopPropagation(); // Prevent tab selection when clicking submenu link
              setOpenSubMenu((prev) => !prev);
            }}
          />
          {openSubMenu && (
            <Menu>
              <ul>
                <li
                  className={`option ${!viewCollection.isDirty ? 'disabled' : ''}`}
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  Save Changes
                </li>
                <li
                  className="option"
                  onClick={() => {
                    handleDelete();
                    setOpenSubMenu(false);
                  }}
                >
                  Delete
                </li>
                <li
                  className="option"
                  onClick={() => {
                    handleCloseAll();
                    setOpenSubMenu(false);
                    return;
                  }}
                >
                  Close
                </li>
              </ul>
            </Menu>
          )}
        </div>
      </div>
    </Tab>
  );
};

export const ViewTab = ({ view_id }: { view_id: string }) => {
  const { setActiveView, activeView, activeModes, setSavedViewIds } = useLayoutContext();
  const currentUserId = useUserId();

  const { mutateAsync: createView } = useSaveView();
  const { mutateAsync: updatePersistedView } = useUpdateView();

  const { viewState, duplicateView, setViewState, deleteView, closeView } = useViewContext();
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const [showSaveNewViewModal, setShowSaveNewViewModal] = useState(false);
  const [showUpdateWithNameModal, setShowUpdateWithNameModal] = useState(false);

  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const subMenuRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(subMenuRef, () => setOpenSubMenu(false));

  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const userId = useUserId();
  const { tenant } = useTenant();
  const queryClient = useQueryClient();

  const cachedUser = queryClient.getQueryData<CRUser>(['user-properties', userId]);

  const handleUpdate = async () => {
    const persistView = constructPersistedView(
      viewState,
      viewState.name || '',
      activeModes,
      currentUserId,
    );
    await updatePersistedView({
      view: persistView,
      view_id: viewState.viewId,
    });
    setViewState((prev) => ({
      ...prev,
      isDirty: false,
    }));
  };

  const handleSaveOrUpdate = async () => {
    if (viewState.viewId === 'default') {
      const defaultViewHash = cachedUser?.preferences?.defaultView?.[tenant];
      if (defaultViewHash) {
        await updatePersistedView({
          view: constructPersistedView(viewState, 'default', activeModes, currentUserId),
          view_id: defaultViewHash,
        });
        setViewState((prev) => ({
          ...prev,
          isDirty: false,
          name: 'default',
          view_id: defaultViewHash,
        }));
        setOpenSubMenu(false);
        return;
      }
      const persistView = constructPersistedView(viewState, 'default', activeModes, currentUserId);
      try {
        const hash = await createView(persistView);
        setViewState((prev) => ({
          ...prev,
          isDirty: false,
          name: 'default',
          view_id: hash,
        }));
        updateUserPreferences({
          defaultView: { ...cachedUser?.preferences?.defaultView, [tenant]: hash },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setOpenSubMenu(false);
      }
      return;
    }
    if (viewState.view_id === '') {
      setShowSaveNewViewModal(true);
    } else if (viewState.isDirty && viewState.view_id !== '') {
      if (!viewState.name || viewState.name === '') {
        setShowUpdateWithNameModal(true);
      }
      handleUpdate();
    }
    setOpenSubMenu(false);
  };

  const viewMode = useMemo(() => {
    return activeModes[view_id] === ViewType.ANALYZE ? 'Assess' : activeModes[view_id];
  }, [activeModes, view_id]);

  return (
    <Tab key={view_id} isActive={activeView === view_id}>
      <div className="tab-content">
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: '5px' }}
          onClick={(e) => {
            e.stopPropagation();
            setActiveView(view_id);
          }}
        >
          {view_id === 'default' && (
            <Icon name={ICONS.Home} width="20" height="20" color="#A0A38D" />
          )}
          <span className="label">
            <Tooltip
              label={`${
                viewState.name === 'default'
                  ? ''
                  : `${viewState.name === '' ? 'Unnamed view' : viewState.name} :`
              } ${viewMode}`}
            >
              {(viewState.isDirty ||
                (viewState.viewId !== 'default' && viewState.view_id === '')) && (
                <span style={{ color: colors.datavis['pink-2'], marginRight: '5px' }}>*</span>
              )}
              {viewState.name === 'default'
                ? ''
                : `${viewState.name === '' ? 'Unnamed view' : viewState.name} :`}{' '}
              {viewMode} - {preparePrimaryLabel(viewState.timeSelection)}
            </Tooltip>
          </span>
        </div>
        <div ref={subMenuRef}>
          <Icon
            className="subMenu-link"
            name={ICONS.Chevron}
            width="10"
            color="#A0A38D"
            onClick={(e) => {
              e.stopPropagation(); // Prevent tab selection when clicking submenu link
              setOpenSubMenu((prev) => !prev);
            }}
          />
          {openSubMenu && (
            <Menu>
              <ul>
                <li
                  className={`option ${
                    viewState.isDirty ||
                    viewState.viewId === 'default' ||
                    (viewState.viewId !== 'default' && viewState.view_id === '')
                      ? ''
                      : 'disabled'
                  }`}
                  onClick={() => {
                    handleSaveOrUpdate();
                  }}
                >
                  {viewState.viewId !== 'default' && viewState.view_id === ''
                    ? 'Save As New View'
                    : 'Save Changes'}
                </li>
                <li
                  className="option"
                  onClick={() => {
                    duplicateView();
                    setOpenSubMenu(false);
                  }}
                >
                  Duplicate
                </li>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      setConfirmDeleteModal(true);
                      setOpenSubMenu(false);
                    }}
                  >
                    Delete
                  </li>
                )}
                <li className="separator"></li>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      setConfirmCloseModal(true);
                      setOpenSubMenu(false);
                      return;
                    }}
                  >
                    Close
                  </li>
                )}
                <li
                  className="option"
                  onClick={async () => {
                    if (!viewState.name || viewState.name === '' || viewState.view_id === '') {
                      setOpenSaveModal(true);
                      return;
                    }
                    // TODO: Autosave view needed?
                    // if (viewState.name !== '' && viewState.view_id !== '' && viewState.isDirty) {
                    //   try {
                    //     const persistView = constructPersistedView(
                    //       viewState,
                    //       viewState.name || '',
                    //       activeModes,
                    //       currentUserId,
                    //     );
                    //     await updatePersistedView({
                    //       view: persistView,
                    //       view_id: viewState.viewId,
                    //     });
                    //     setViewState((prev) => ({
                    //       ...prev,
                    //       isDirty: false,
                    //     }));
                    //   } catch (error) {
                    //     console.error(error);
                    //   }
                    // }
                    setShowShareModal(true);
                    setOpenSubMenu(false);
                  }}
                >
                  Share
                </li>
              </ul>
            </Menu>
          )}
        </div>
      </div>
      {/* Save new view Modal */}
      {showSaveNewViewModal && (
        <SaveViewModal
          onClose={() => {
            setShowSaveNewViewModal(false);
          }}
          viewState={viewState}
          onSave={async (name) => {
            if (viewState.view_id === '') {
              const persistView = constructPersistedView(
                viewState,
                name,
                activeModes,
                currentUserId,
              );
              try {
                const hash = await createView(persistView);
                setViewState((prev) => ({
                  ...prev,
                  isDirty: false,
                  name: name,
                  view_id: hash,
                }));
                setSavedViewIds((prev) => Array.from(new Set([...prev, hash])));
              } catch (error) {
                console.error(error);
              }
            }
            setShowSaveNewViewModal(false);
          }}
        />
      )}
      {showUpdateWithNameModal && (
        <SaveViewModal
          onClose={() => {
            setShowUpdateWithNameModal(false);
          }}
          viewState={viewState}
          onSave={async (name) => {
            try {
              const persistView = constructPersistedView(
                viewState,
                name,
                activeModes,
                currentUserId,
              );
              await updatePersistedView({
                view: persistView,
                view_id: viewState.viewId,
              });
              setViewState((prev) => ({
                ...prev,
                isDirty: false,
                name: name,
              }));
              setShowUpdateWithNameModal(false);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      )}
      {openSaveModal && (
        <SaveViewModal
          onClose={() => {
            setOpenSaveModal(false);
          }}
          viewState={viewState}
          onSave={async (name) => {
            if (viewState.view_id === '') {
              const persistView = constructPersistedView(
                viewState,
                name,
                activeModes,
                currentUserId,
              );
              const hash = await createView(persistView);
              setViewState((prev) => ({
                ...prev,
                isDirty: false,
                name: name,
                view_id: hash,
              }));
            } else {
              try {
                const persistView = constructPersistedView(
                  viewState,
                  name,
                  activeModes,
                  currentUserId,
                );
                await updatePersistedView({
                  view: persistView,
                  view_id: viewState.viewId,
                });
                setViewState((prev) => ({
                  ...prev,
                  isDirty: false,
                  name: name,
                }));
              } catch (error) {
                console.error(error);
              }
            }
            setShowShareModal(true);
          }}
        />
      )}
      {confirmCloseModal && (
        <ConfirmCloseModal
          onConfirm={async (state) => {
            if (state) await closeView();
            setConfirmCloseModal(false);
          }}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDeleteModal
          onConfirm={async (state) => {
            if (state) {
              if (viewState.view_id === '') closeView();
              await deleteView();
            }
            setConfirmDeleteModal(false);
          }}
        />
      )}
      {showShareModal && <ShareViewLinkModal view={viewState} onClose={setShowShareModal} />}
    </Tab>
  );
};

const FieldLabel = styled.label`
  color: #a0a38d;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  margin-bottom: 5px;
  display: inline-block;
`;

const SaveViewModal = ({
  onClose,
  viewState,
  onSave,
}: {
  onClose: (closeState: boolean) => void;
  viewState: ViewState;
  onSave: (name: string) => void;
}) => {
  const { register, watch } = useForm<{ name: string }>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
    },
  });
  return (
    <Modal open={true}>
      <ModalContents
        title={'Save View'}
        styles={{ content: { maxWidth: '450px' } }}
        confirmButtonText="Save View"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onClose(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onSave(watch(`name`));
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Save the time range, tags, and charting options you are currently using as a View.</p>
          <div>
            <FieldLabel>Name</FieldLabel>
            <TextInput
              type="string"
              value={watch(`name`)}
              className="view-name"
              label=""
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              placeholder=""
              errorId={'view-name-required'}
              disabled={false}
              {...register(`name`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view name';
                  }
                  return true;
                },
              })}
              errorMessage={''}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldLabel>Timeframe</FieldLabel>
            <div>{preparePrimaryLabel(viewState.timeSelection)}</div>
          </div>
        </div>
      </ModalContents>
    </Modal>
  );
};

const ConfirmCloseModal = ({ onConfirm }: { onConfirm: (closeState: boolean) => void }) => {
  return (
    <Modal open={true}>
      <ModalContents
        title={'Confirm to close view'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Close View"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onConfirm(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onConfirm(true);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Are you sure you want to close this view?</p>
        </div>
      </ModalContents>
    </Modal>
  );
};
const ConfirmDeleteModal = ({ onConfirm }: { onConfirm: (closeState: boolean) => void }) => {
  return (
    <Modal open={true}>
      <ModalContents
        title={'Confirm to delete'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onConfirm(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onConfirm(true);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Are you sure you want to delete?</p>
        </div>
      </ModalContents>
    </Modal>
  );
};

const ShareViewLinkModal = ({
  view,
  onClose,
}: {
  view: ViewState;
  onClose: (closeState: boolean) => void;
}) => {
  const { tenant } = useTenant();
  const viewLink = `${window.location.origin}${generatePath(Paths.SHARE, {
    tenantId: tenant.toString(),
    hash: view.view_id,
  })}`;
  return (
    <Modal open={true}>
      <ModalContents
        title={'Share View'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Close"
        closeButtonCallback={() => {
          onClose(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onClose(false);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <ExportModal view={view} link={viewLink} isError={false} />
      </ModalContents>
    </Modal>
  );
};
