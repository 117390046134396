import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import { useViewContext } from '../../context/view-context';
import AlertModal from '../alert-modal/alert-modal';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemViewContainer,
  SubSystemContainer,
  SystemSectionContainer,
  SystemViewContainer,
} from './styles/system-styles';

import { Icon, Checkbox, Tooltip, OptionsGroup, Button, Ul, Li } from '@controlrooms/components';
import { ICONS, SUB_SYSTEMS_SEARCH_PARAM, TAGS_SEARCH_PARAM } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { SubSystem, Checkable, Navigable, Plant, System } from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

const NavIcon: React.FC<{ onClick: () => void; dataTestId: string; hasTags: boolean }> = ({
  onClick = () => undefined,
  dataTestId,
  hasTags,
}) => (
  <div
    className="nav-icon"
    onClick={onClick}
    data-testid={dataTestId}
    style={{ visibility: hasTags ? 'visible' : 'hidden' }}
  >
    <Icon height="6" name={ICONS.Chevron} width="11" />
  </div>
);

type SubSystemViewProps = SubSystem &
  Checkable &
  Navigable & { depth?: number; defaultExpandLevels?: number };

const SubSystemView: React.FC<SubSystemViewProps> = React.memo(
  ({
    depth = 1,
    description,
    folder: id,
    handleCheckboxCheck,
    name,
    onNav = () => undefined,
    selectedFolders,
    subfolders,
    tags,
    defaultExpandLevels = 2,
  }) => {
    const hasSubfolders = useMemo(() => Boolean(subfolders?.length), [subfolders?.length]);
    const hasTags = useMemo(() => Boolean(tags?.length), [tags?.length]);
    const { track } = useAnalytics();
    const navigate = useNavigate();
    const { tenant } = useTenant();
    const [search] = useSearchParams();
    const { viewState, setViewState } = useViewContext();
    const isTrendSearch = viewState.isTrendSearch;
    const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
    const tagNameParams = search.get(TAGS_SEARCH_PARAM);

    // state
    const [expanded, setExpanded] = useState(defaultExpandLevels > 0); // Expand based on the level

    // handlers
    const handleExpand = () => {
      track('Analyze - SubSystem View', {
        expandSubFolders: expanded,
        expandedSystemId: id,
        expandedSystemName: name,
      });
      setExpanded((prev) => !prev);
    };

    const removeQueryParams = useCallback(() => {
      navigate({
        pathname: generatePath(Paths.ANALYZE, {
          tenantId: tenant.toString(),
        }),
        search: '',
      });
    }, [navigate, tenant]);

    const handleCheckboxChange = useCallback(() => {
      if (subSystemParams || tagNameParams) {
        removeQueryParams();
      }
      track('Analyze - SubSystem View', {
        selectedSystemId: id,
        selectedSystemName: name,
      });
      handleCheckboxCheck(id as number);
      setViewState((prev) => ({
        ...prev,
        isDirty: true,
      }));
    }, [
      subSystemParams,
      tagNameParams,
      track,
      id,
      name,
      handleCheckboxCheck,
      setViewState,
      removeQueryParams,
    ]);

    const handleNav = () => id && onNav(id);

    // Check if the folder is selected
    const isChecked = useMemo(() => selectedFolders?.includes(id as number), [selectedFolders, id]);

    const subSystemViewContainerClasses = classNames({
      expanded: expanded,
      'has-tags': hasTags,
      'has-subfolders': hasSubfolders,
      'is-child': depth > 1,
    });

    const handleNavClick = () => {
      track('Analyze - SubSystem View', {
        navigateToFolderWithId: id,
        navigate: 'clicked',
        hasTags: true,
      });
      handleNav();
    };
    return (
      <SubSystemViewContainer className={subSystemViewContainerClasses} depth={depth}>
        <div className={`header ${isChecked ? 'checked' : ''}`}>
          <SubSystemContainer depth={depth}>
            <Checkbox
              dataTestId={`system-view-checkbox-${name}`}
              checked={Boolean(isChecked)}
              className="sub-system-check"
              onChange={handleCheckboxChange}
            />
            <div
              className="expand-icon"
              onClick={handleExpand}
              style={{ visibility: hasSubfolders ? 'visible' : 'hidden' }}
            >
              {hasSubfolders && <ExpandIcon />}
            </div>
            <div
              data-testid={`system-view-${name}`}
              className="name truncate"
              onClick={() => {
                track('Analyze - SubSystem View', {
                  checkedSystemId: id,
                  checkedSystemName: name,
                });
                handleCheckboxCheck(id as number);
                return;
              }}
            >
              <Tooltip label={name || ''} place="top">
                {name}
              </Tooltip>
            </div>
            <div
              className="description"
              onClick={(e) => {
                e.preventDefault();
                track('Analyze - SubSystem View', {
                  navigateToFolderWithId: id,
                  navigate: 'clicked',
                });
                handleNav();
              }}
            >
              {description && name && <span>:&nbsp;</span>}
              {description}
            </div>
            {!isTrendSearch && (
              <>
                <SystemMenu
                  handleNavClick={handleNavClick}
                  targetId={id as number}
                  name={name || ''}
                />
                <NavIcon
                  dataTestId={`navigate-to-tags-${name}`}
                  onClick={handleNavClick}
                  hasTags={hasTags}
                />
              </>
            )}
          </SubSystemContainer>
        </div>

        {expanded && (
          <div className="content">
            {hasSubfolders &&
              subfolders?.map((subfolder) => (
                <SubSystemView
                  key={`${id}-${subfolder.folder}`}
                  depth={depth + 1}
                  handleCheckboxCheck={handleCheckboxCheck}
                  onNav={onNav}
                  selectedFolders={selectedFolders}
                  defaultExpandLevels={defaultExpandLevels - 1} // Reduce the expand level for subfolders
                  {...subfolder}
                />
              ))}
          </div>
        )}
      </SubSystemViewContainer>
    );
  },
);

type SystemSectionProps = Partial<System<SubSystem>> &
  Checkable &
  Navigable & { defaultExpandLevels?: number };

export const SystemSection: React.FC<SystemSectionProps> = React.memo(
  ({
    folder,
    name,
    onNav = () => undefined,
    description,
    subfolders,
    handleCheckboxCheck,
    selectedFolders,
    defaultExpandLevels = 2,
  }) => {
    // state
    const [expanded, setExpanded] = useState(defaultExpandLevels > 0); // Expand by default if within defaultExpandLevels
    const { track } = useAnalytics();
    // Handle expand/collapse of the folder
    const handleExpand = useCallback(() => {
      if (subfolders?.length) {
        setExpanded((prev) => !prev);
      }
    }, [subfolders?.length]);

    return (
      <SystemSectionContainer className={expanded ? 'expanded' : ''}>
        <div
          className="section-header plant-name"
          data-testid="section-header-plant-name"
          onClick={() => {
            track('Analyze - System Section', {
              expandSection: expanded,
              expandedSectionName: name,
            });
            handleExpand();
          }}
        >
          <div className="icon-wrapper">{!!subfolders?.length && <ExpandIcon />}</div>
          {name && (
            <div className="name">
              <Tooltip label={name}>{name}</Tooltip>
            </div>
          )}
          <div className="description">
            {description && <Tooltip label={description}>{description}</Tooltip>}
            {description && name && <span>:&nbsp;</span>}
          </div>
        </div>

        {expanded && (
          <div className="section-content">
            {subfolders?.map((subfolder) => (
              <SubSystemView
                handleCheckboxCheck={handleCheckboxCheck}
                key={sanitizeString(`${folder}-${subfolder.name}`)}
                onNav={onNav}
                selectedFolders={selectedFolders}
                defaultExpandLevels={defaultExpandLevels - 1}
                {...subfolder}
              />
            ))}
          </div>
        )}
      </SystemSectionContainer>
    );
  },
);

export interface SystemViewProps extends Checkable, Navigable {
  plant?: Plant;
  defaultExpandLevels?: number;
}

export const SystemView: React.FC<SystemViewProps> = React.memo(
  ({ handleCheckboxCheck, onNav, plant, selectedFolders, defaultExpandLevels = 1 }) => {
    const systems = plant?.subfolders ?? [];

    return (
      <SystemViewContainer>
        {systems.map((system) => (
          <SystemSection
            handleCheckboxCheck={handleCheckboxCheck}
            key={system.folder}
            onNav={onNav}
            selectedFolders={selectedFolders}
            defaultExpandLevels={defaultExpandLevels}
            {...system}
          />
        ))}
      </SystemViewContainer>
    );
  },
);

const SystemMenu = ({
  handleNavClick,
  targetId,
  name,
}: {
  handleNavClick: () => void;
  targetId: number;
  name: string;
}) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const { canUserReadAlert } = useContext(AuthorizationContext);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup className="menu-select">
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`system_view_menu_${name}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul isOpen={isMenuSelectOpen} position="absolute" className="dropdown" ref={ulRef}>
        <Li data-testid="view-tags" key="view-in-analyze">
          <div onClick={handleNavClick}>View Tags</div>
        </Li>
        {canUserReadAlert && (
          <Li data-testid={`${name}-manage-alert`} key="manage-alert">
            <AlertModal targetType="system" targetId={targetId}>
              <div>Manage Alerts</div>
            </AlertModal>
          </Li>
        )}
      </Ul>
    </OptionsGroup>
  );
};
