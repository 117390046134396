import { AxiosHeaderValue, AxiosResponseHeaders } from 'axios';
import dayjs from 'dayjs';

import {
  ApiListResult,
  ApiResult,
  CRAPITagData,
  CRFolderTags,
  CRTagAnomaly,
  CRTagData,
  CRTagMinMaxTimeSeries,
  CRTagMinMaxTimeSeriesString,
  CRTagMode,
  TimeSearchResult,
} from '../api';
import { TagResampleMinMaxPayload } from '../tag';

import { HiddenTags } from './analyze-system-browser';

import { TimeValue, TimeValueString } from './index';

import { mapAnomalies } from '@controlrooms/utils';

export type CustomHeader = AxiosResponseHeaders | Partial<AxiosHeaderValue>;
export class TimeSeriesString implements TimeValueString {
  time: number;
  timestamp: string | number;
  value: string | number;

  constructor(init: CRTagMinMaxTimeSeriesString) {
    this.time = dayjs(init.time).valueOf();
    this.timestamp = Number(init.time);
    this.value = init.value;
  }
}

export class TimeSeries implements TimeValue {
  max?: number;
  min?: number;
  time: number;
  timestamp: string | number;
  value: number;
  frequent: CRTagMode | number | undefined;

  constructor(init: CRTagMinMaxTimeSeries) {
    this.max = init.max_value;
    this.min = init.min_value;
    this.time = dayjs(init.time).valueOf();
    this.timestamp = Number(init.time);
    this.value = init.value;
    this.frequent = init.frequent;
  }
}

export class Mode {
  start: number;
  startTimestamp: string;
  end: number;
  endTimestamp: string;
  value: number;
  type: string;
  tagName?: string;
  limitCondition: string;
  limitId: string | number;

  constructor(init: CRTagMode, value: number, type: string, tagName?: string) {
    this.start = dayjs(init.start_time).valueOf();
    this.startTimestamp = init.start_time;
    this.end = dayjs(init.end_time).valueOf();
    this.endTimestamp = init.end_time;
    this.value = value;
    this.type = type || '';
    this.tagName = tagName || '';
    this.limitCondition = init.limit_condition || 'n/a'; // set 'n/a' if it doesn't come from the payload
    this.limitId = init.limit_id || '';
  }
}

export interface AnomalyRange {
  severity: number;
  start: number;
  end: number;
  yScale: TimeValue[];
}

// export class TagSearchMinMaxResult {
//   folder: number;
//   tagData: CRTagData<TimeSeries, Mode, AnomalyRange>[];

//   constructor(init: CRTagSearchMinMaxResult, interval: number) {
//     this.folder = init.folder;
//     this.tagData = init.tag_data.map((d) => {
//       const timeseries = (d.timeseries ?? []).map((ts) => new TimeSeries(ts));
//       /* eslint-disable @typescript-eslint/no-explicit-any */
//       return {
//         ...d,
//         anomalies: mapAnomalies(d, interval, timeseries),
//         modes: (d.modes ?? [])?.map((m: any) => new Mode(m, m.frequent, 'frequent', d.tag)),
//         highs: (d.highs ?? [])?.map((h: any) => new Mode(h, h.high_limit, 'highs', d.tag)),
//         highHighs: (d.high_highs ?? [])?.map(
//           (hh: any) => new Mode(hh, hh.high_high_limit, 'highHighs', d.tag),
//         ),
//         lows: (d.lows ?? [])?.map((l: any) => new Mode(l, l.low_limit, 'lows', d.tag)),
//         lowLows: (d.low_lows ?? [])?.map(
//           (ll: any) => new Mode(ll, ll.low_low_limit, 'lowLows', d.tag),
//         ),
//         timeseries,
//       };
//       /* eslint-enable @typescript-eslint/no-explicit-any */
//     });
//   }
// }

// export class SearchTagsMinMaxResult implements ApiListResult<TagSearchMinMaxResult> {
//   result: TagSearchMinMaxResult[];
//   request: Record<string, string | number>;
//   headers: CustomHeader;

//   constructor(init: ApiListResult<CRTagSearchMinMaxResult>) {
//     const { interval } = init.result as unknown as TagSearchMinMaxPayload;
//     this.result = (init.result ?? []).map((d) => new TagSearchMinMaxResult(d, interval)) ?? [];
//     this.request = init.request as Record<string, string | number>;
//     this.headers = init.headers;
//   }
// }

interface fetchAllHiddenTagsResponse {
  hidden_tags: HiddenTags[];
}
export class FetchHiddenTagsResult {
  result: HiddenTags[];
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiResult<fetchAllHiddenTagsResponse>) {
    this.result = init.result?.hidden_tags ?? [];
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export class DeleteHiddenTagsResult {
  result: null;
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiListResult<HiddenTags>) {
    this.result = null;
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export class HideTagsResult {
  result: HiddenTags;
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiResult<HiddenTags>) {
    this.result = init.result;
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export interface TagState {
  state_name: string;
  threshold_type: string;
  state_value: string;
  state_id: 1;
}

interface TagStateResponse {
  tag_states: TagState[];
}
export class TagStateResult {
  result: TagState[];
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiResult<TagStateResponse>) {
    this.result = init.result.tag_states;
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export class TransformedTagData {
  result: CRTagData<TimeSeries, Mode, AnomalyRange>[];

  constructor(
    init: CRAPITagData<CRTagMinMaxTimeSeries, CRTagMode, CRTagAnomaly>[],
    interval: number,
  ) {
    const getMinMaxModeValue = (modes: Mode[]) => {
      if (modes.length === 0) return { min: null, max: null };

      if (modes.length === 1) {
        const singleValue = modes[0].value ?? 0;
        return { min: singleValue - 1, max: singleValue + 1 };
      }

      const result = modes.reduce(
        (acc, mode) => {
          if (typeof mode.value !== 'number') {
            throw new Error('All Mode objects must have a numeric "value" property.');
          }
          acc.min = Math.min(acc.min, mode.value);
          acc.max = Math.max(acc.max, mode.value);
          return acc;
        },
        { min: modes[0].value, max: modes[0].value },
      );

      return result;
    };

    this.result = (init ?? []).map((d) => {
      const timeseries = (d.timeseries ?? []).map((ts) => new TimeSeries(ts));

      const ranges = (d.timeseries ?? []).reduce(
        (acc, dataPoint) => {
          if (dataPoint.min_value !== null && dataPoint.min_value !== undefined) {
            acc.min =
              acc.min === null ? dataPoint.min_value : Math.min(acc.min, dataPoint.min_value);
          }
          if (dataPoint.max_value !== null && dataPoint.max_value !== undefined) {
            acc.max =
              acc.max === null ? dataPoint.max_value : Math.max(acc.max, dataPoint.max_value);
          }
          return acc;
        },
        { min: null as number | null, max: null as number | null },
      );

      return {
        ...d,
        anomalies: mapAnomalies(d, interval, timeseries),
        modes: (d.modes ?? []).flatMap((m) =>
          'frequent' in m ? [new Mode(m, m.frequent, 'frequent', d.tag)] : [],
        ),
        highs: (d.highs ?? []).flatMap((h) =>
          'high_limit' in h ? [new Mode(h, h.high_limit, 'highs', d.tag)] : [],
        ),
        highHighs: (d.high_highs ?? []).flatMap((hh) =>
          'high_high_limit' in hh ? [new Mode(hh, hh.high_high_limit, 'highHighs', d.tag)] : [],
        ),
        lows: (d.lows ?? []).flatMap((l) =>
          'low_limit' in l ? [new Mode(l, l.low_limit, 'lows', d.tag)] : [],
        ),
        lowLows: (d.low_lows ?? []).flatMap((ll) =>
          'low_low_limit' in ll ? [new Mode(ll, ll.low_low_limit, 'lowLows', d.tag)] : [],
        ),
        timeseries,
        ranges: {
          timeseries: ranges,
          frequent: getMinMaxModeValue(
            (d.modes ?? [])?.flatMap((m) =>
              'frequent' in m ? [new Mode(m, m.frequent, 'frequent', d.tag)] : [],
            ),
          ),
          highs: getMinMaxModeValue(
            (d.highs ?? [])?.flatMap((h) =>
              'high_limit' in h ? [new Mode(h, h.high_limit, 'highs', d.tag)] : [],
            ),
          ),
          highHighs: getMinMaxModeValue(
            (d.high_highs ?? [])?.flatMap((hh) =>
              'high_high_limit' in hh ? [new Mode(hh, hh.high_high_limit, 'highHighs', d.tag)] : [],
            ),
          ),
          lows: getMinMaxModeValue(
            (d.lows ?? [])?.flatMap((l) =>
              'low_limit' in l ? [new Mode(l, l.low_limit, 'lows', d.tag)] : [],
            ),
          ),
          lowLows: getMinMaxModeValue(
            (d.low_lows ?? [])?.flatMap((ll) =>
              'low_low_limit' in ll ? [new Mode(ll, ll.low_low_limit, 'lowLows', d.tag)] : [],
            ),
          ),
        },
      };
    });
  }
}

export class ResampleTagsMinMaxResult
  implements ApiListResult<CRTagData<TimeSeries, Mode, AnomalyRange>>
{
  result: CRTagData<TimeSeries, Mode, AnomalyRange>[];
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiListResult<CRAPITagData<CRTagMinMaxTimeSeries, CRTagMode, CRTagAnomaly>>) {
    const { interval } = init.request as unknown as TagResampleMinMaxPayload;
    const transformedData = new TransformedTagData(init.result, Number(interval));

    this.result = transformedData.result;
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}
export interface TooltipData extends TimeSeries {
  frequent: number;
  uom: string;
}
export interface TooltipContentData {
  tooltipData: TooltipData[];
  limitArrayData: Mode[];
}

export class AnomalousTagsResult implements ApiListResult<CRFolderTags> {
  result: CRFolderTags[];
  request: Record<string, string | number[]>;
  headers: CustomHeader;

  constructor(init: ApiListResult<CRFolderTags>) {
    const _result = init.result ?? [];

    this.request = init.request as Record<string, string | number[]>;
    this.headers = init.headers;

    if (!init.result) {
      this.result = (this.request.folders as unknown as number[]).map((f) => ({
        folder: f,
        tags: [],
      }));
    } else {
      (this.request.folders as unknown as number[]).forEach((f) => {
        if (!_result.find((t) => t.folder === f)) {
          _result.push({ folder: f, tags: [] });
        }
      });
      this.result = _result;
    }
  }
}
export class EventSearchResult implements ApiListResult<TimeSearchResult> {
  result: TimeSearchResult[];
  request: Record<string, string | number[]>;
  headers: CustomHeader;

  constructor(init: ApiListResult<TimeSearchResult>) {
    this.result = init.result ?? [];

    this.request = init.request as Record<string, string | number[]>;
    this.headers = init.headers;
  }
}
