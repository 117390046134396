export { Icon } from './icon';

import { ReactComponent as AddChannel } from './icons/add-channel.svg';
import { ReactComponent as AnalyzeLink } from './icons/analyze-link.svg';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as Back } from './icons/back.svg';
import { ReactComponent as Bell } from './icons/bell.svg';
import { ReactComponent as BurgerMenu } from './icons/burger-menu.svg';
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as CheckmarkDefault } from './icons/checkmark-default.svg';
import { ReactComponent as Checkmark } from './icons/checkmark.svg';
import { ReactComponent as Chevron } from './icons/chevron.svg';
import { ReactComponent as CloseX } from './icons/close-x.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as ClosedEye } from './icons/closed-eye.svg';
import { ReactComponent as Comment } from './icons/comment.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Dynamic } from './icons/dynamic.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Envelope } from './icons/envelope.svg';
import { ReactComponent as Export } from './icons/export.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as Folder } from './icons/folder.svg';
import { ReactComponent as GridActive } from './icons/grid-active.svg';
import { ReactComponent as Grid } from './icons/grid.svg';
import { ReactComponent as Help } from './icons/help.svg';
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as Indeterminate } from './icons/indeterminate.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as JumpBack } from './icons/jump-back.svg';
import { ReactComponent as Kebab } from './icons/kebab.svg';
import { ReactComponent as Limit } from './icons/limits.svg';
import { ReactComponent as ListActive } from './icons/list-active.svg';
import { ReactComponent as List } from './icons/list.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Minus } from './icons/minus.svg';
import { ReactComponent as Now } from './icons/now.svg';
import { ReactComponent as OverlayActive } from './icons/overlay-active.svg';
import { ReactComponent as Overlay } from './icons/overlay.svg';
import { ReactComponent as PanLeft } from './icons/pan-left.svg';
import { ReactComponent as PanRight } from './icons/pan-right.svg';
import { ReactComponent as Pin } from './icons/pin.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as RowDragHandle } from './icons/row-grab-handle.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as SelectTrend } from './icons/select-trend.svg';
import { ReactComponent as Sparkline } from './icons/sparkline.svg';
import { ReactComponent as Spinner } from './icons/spinner.svg';
import { ReactComponent as SystemDown } from './icons/system-down.svg';
import { ReactComponent as System } from './icons/system.svg';
import { ReactComponent as Teams } from './icons/teams-logo.svg';
import { ReactComponent as ThumbDown } from './icons/thumb-down.svg';
import { ReactComponent as ThumbUp } from './icons/thumb-up.svg';
import { ReactComponent as TooltipArrow } from './icons/tooltip-arrow.svg';
import { ReactComponent as Undo } from './icons/undo.svg';
import { ReactComponent as Union } from './icons/union.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as ZoomIn } from './icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from './icons/zoom-out.svg';

import { ICONS } from '@controlrooms/constants';

export { StyledIcon } from './icon';

export const icons = {
  [ICONS.AddChannel]: AddChannel,
  [ICONS.AnalyzeLink]: AnalyzeLink,
  [ICONS.Arrow]: Arrow,
  [ICONS.ArrowDown]: ArrowDown,
  [ICONS.ArrowRight]: ArrowRight,
  [ICONS.ArrowUp]: ArrowUp,
  [ICONS.Back]: Back,
  [ICONS.Bell]: Bell,
  [ICONS.BurgerMenu]: BurgerMenu,
  [ICONS.Teams]: Teams,
  [ICONS.Comment]: Comment,
  [ICONS.Info]: Info,
  [ICONS.Calendar]: Calendar,
  [ICONS.Checkmark]: Checkmark,
  [ICONS.Check]: Check,
  [ICONS.Overlay]: Overlay,
  [ICONS.OverlayActive]: OverlayActive,
  [ICONS.List]: List,
  [ICONS.ListActive]: ListActive,
  [ICONS.Grid]: Grid,
  [ICONS.GridActive]: GridActive,
  [ICONS.Indeterminate]: Indeterminate,
  [ICONS.JumpBack]: JumpBack,
  [ICONS.Chevron]: Chevron,
  [ICONS.Delete]: Delete,
  [ICONS.Download]: Download,
  [ICONS.Copy]: Copy,
  [ICONS.Dynamic]: Dynamic,
  [ICONS.Edit]: Edit,
  [ICONS.Envelope]: Envelope,
  [ICONS.ClosedEye]: ClosedEye,
  [ICONS.Eye]: Eye,
  [ICONS.Filter]: Filter,
  [ICONS.RowDragHandle]: RowDragHandle,
  [ICONS.Undo]: Undo,
  [ICONS.ZoomIn]: ZoomIn,
  [ICONS.ZoomOut]: ZoomOut,
  [ICONS.Close]: Close,
  [ICONS.CloseX]: CloseX,
  [ICONS.Help]: Help,
  [ICONS.Home]: Home,
  [ICONS.Folder]: Folder,
  [ICONS.Kebab]: Kebab,
  [ICONS.Limits]: Limit,
  [ICONS.Plus]: Plus,
  [ICONS.Pin]: Pin,
  [ICONS.Search]: Search,
  [ICONS.SelectTrend]: SelectTrend,
  [ICONS.Sparkline]: Sparkline,
  [ICONS.Spinner]: Spinner,
  [ICONS.System]: System,
  [ICONS.SystemDown]: SystemDown,
  [ICONS.Menu]: Menu,
  [ICONS.Minus]: Minus,
  [ICONS.TooltipArrow]: TooltipArrow,
  [ICONS.Export]: Export,
  [ICONS.Union]: Union,
  [ICONS.Upload]: Upload,
  [ICONS.ThumbUp]: ThumbUp,
  [ICONS.ThumbDown]: ThumbDown,
  [ICONS.PanRight]: PanRight,
  [ICONS.PanLeft]: PanLeft,
  [ICONS.CheckmarkDefault]: CheckmarkDefault,
  [ICONS.Now]: Now,
};
